import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import { graphql } from 'gatsby';
import UsefulLinksComponent from '../components/UsefulLinksComponent';

const UsefulLinks: React.FC = () => {
  return (
    <Layout id="usefulLinks">
      <SEO title="UsefulLinks" />
      <UsefulLinksComponent />
    </Layout>
  );
};

export default UsefulLinks;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
