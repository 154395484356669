import React from 'react';

import Container from 'components/ui/Container';
import HorizontalTitleSeparator from '../HorizontalTitleSeparator/HorizontalTitleSeparator';
import CopyText from '../CopyText';
import { useI18next } from 'gatsby-plugin-react-i18next';
import * as styles from './styles.module.scss';

const Education: React.FC = () => {
  const { t } = useI18next();

  return (
    <Container section>
      <HorizontalTitleSeparator title={t('usefulLinks')} additionalClass="mt-16 mb-24" />
      <div className={styles.linkContainer}>
        <CopyText variant="copy-2" additionalClass="mb-16">
          {t('usefulLinksIntro')}
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Portalul instanțelor de judecată -{' '}
          <a href="http://portal.just.ro/SitePages/acasa.aspx">http://portal.just.ro/SitePages/acasa.aspx</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Curtea de Apel Cluj - <a href="https://www.curteadeapelcluj.ro/">https://www.curteadeapelcluj.ro/</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Ministerul Justiției - <a href="https://www.just.ro/">https://www.just.ro/</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Uniunea Națională a Barourilor din România - <a href="https://www.unbr.ro/">https://www.unbr.ro/</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Baroul Cluj - <a href="https://www.baroul-cluj.ro/">https://www.baroul-cluj.ro/</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Înalta Curte de Casaţie şi Justiţie a României - <a href="https://www.scj.ro">www.scj.ro</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Curtea Constituţională a României - <a href="https://www.ccr.ro">www.ccr.ro</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Curtea Europeană a Drepturilor Omului - <a href="https://www.echr.coe.int">www.echr.coe.int</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Curtea de Justiţie a Comunităţilor Europene - <a href="http://curia.eu">http://curia.eu</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Ministerul Finanțelor publice -{' '}
          <a href="https://mfinante.gov.ro/ro/web/site">https://mfinante.gov.ro/ro/web/site</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Bursa de valori București - <a href="https://www.bvb.ro/">https://www.bvb.ro/</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Camera de comerț și industrie a României - <a href="https://ccir.ro/">https://ccir.ro/</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Ministerul Muncii și al Solidarității Sociale -{' '}
          <a href="http://mmuncii.ro/j33/index.php/ro/">http://mmuncii.ro/j33/index.php/ro/</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Confederatia Nationala Sindicala Cartel Alfa -{' '}
          <a href="https://www.cartel-alfa.ro/">https://www.cartel-alfa.ro/</a>
        </CopyText>
        <CopyText variant="copy-2" additionalClass="mb-16">
          Monitorul Oficial - <a href="https://www.monitoruloficial.ro/">https://www.monitoruloficial.ro/</a>
        </CopyText>
      </div>
    </Container>
  );
};

export default Education;
